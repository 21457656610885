import './App.css';
import { useMemo } from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <div>
      <nav>
        <div className="nav-container">
          <img className="nav-logo" src="/img/logo.jpg" alt="" />
          <div className="social-icons">
          {/*<div className="social-icons hide-800">*/}
            <a href="https://twitter.com/crypto_bastards" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://t.me/+95zasupEXv5lMTc0" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/telegram.svg" alt="" />
            </a>
          </div>
        </div>
      </nav>
      <div className="content-wrapper">
          <header id="link1">

              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      <Home
                        candyMachineId={candyMachineId}
                        connection={connection}
                        txTimeout={DEFAULT_TIMEOUT}
                        rpcHost={rpcHost}
                        network={network}
                      />
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>

          </header>
          <div id="link4" className="container faq">
            <h1 style={{ padding: "0 0 24px 0" }}>FAQ</h1>
            <div>
              <h4>¿Qué es CryptoBastards? </h4>
              <p>
                CryptoBastards es un club internacional de crypto esports, también llamados juegos blockchain, fundado en 2021. Principalmente brindamos apoyo a la industria y construimos una comunidad alrededor del GameFi.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué puedo hacer en esta web?</h4>
              <p>
                Aquí podrás mintear el NFT que representa la avatar de CryptoBastards a través de un wallet de Solana como 
                <span> </span><a href="https://phantom.app/download" target="_blank" rel="noreferrer">Phantom</a>.<span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Para qué sirve el avatar de CryptoBastards?</h4>
              <p>
                Los usuarios que tengan el avatar podrán vincularlo a sus redes sociales como foto de perfil. También tras trabajar con web3 podrás cargarlo vía blockchain. El avatar es un regalo para toda nuestra comunidad y no representa ningún caso de uso.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué es un NFT?</h4>
              <p>
                Sus siglas en inglés significan Non Fungible Token, o Tokens no fungibles, en castellano, que viene a decir que es un tipo de token digital único, un certificado digital de autenticidad que mediante la tecnología blockchain, la misma que se emplea en las criptomonedas, se asocia a un único archivo digital.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué significa mintear o minting?</h4>
              <p>
                Es el proceso de crear o generar un NFT.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Cuántos NFT de avatares de CryptoBastards hay?</h4>
              <p>
                En total, habrá 1000 NFT, 900 normales, 90 raros y 10 súper raros!
                <br/ >
                <br/ >
                Avatar súper raro: Solamente habrá 10 unidades de este avatar super raro, te deseamos mucha suerte para que puedas conseguirlo! 
                <br/ >
                Avatar raro: 3 tipos distintos de NFT y 90 unidades en total, 30 por cada tipo. 
                <br/ >
                Avatar normal: 6 tipos distintos de NFT y 900 unidades en total, 150 por cada tipo.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Cómo puedo conseguir el NFT del avatar?</h4>
              <p>
                Simplemente deberás estar el día y la hora del mint y estar listo para mintear! En <span> </span><a href="https://t.me/c/1579889913/122" target="_blank" rel="noreferrer">este tutorial</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path><span> </span></svg>podrás ver cómo hacerlo!
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué coste tiene el NFT?</h4>
              <p>
                Mintear el NFT es gratis, solo deberás pagar la comisión de la blockchain por mintear en Solana, el coste puede variar según el día. Tener entre 0.1 y 0.01 SOL en tu billetera debería ser suficiente.
                <br/ >
                <br/ >
                Una vez minteado, el NFT se puede comercializar en mercados NFT de Solana como Magic Eden, y los usuarios son libres de venderlo si quieren y reciben ofertas por ello. El NFT cuenta con un royalty del 10%, es decir, CryptoBastards recibirá una comisión del 3% del total pagado en ventas secundarias.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Cuándo es la fecha del minting?</h4>
              <p>
                <strong>31 de Junio de 2022</strong>
                <br/ ><br/ >
                - 21 de Junio a las 7pm UTC: minting exclusivo para los usuarios en la Whitelist<br/ >
                - 22 de Junio a las 7pm UTC: minting público, hasta que se acaben los 1000 NFTs
              </p>

              <hr />
            </div>

            <div>
              <h4>Puedes seguir a nuestro equipo en nuestras redes sociales: </h4>
              <p>
                -<span> </span><a href="https://twitter.com/crypto_bastards" target="_blank" rel="noreferrer">Twitter</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
                <br/ >
                -<span> </span><a href="https://t.me/+95zasupEXv5lMTc0" target="_blank" rel="noreferrer">Canal de Telegram</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
                <br/ >
                -<span> </span><a href="https://t.me/+LPH_y6NSf-1jODQ0" target="_blank" rel="noreferrer">Chat de Telegram</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              </p>
              <hr />
            </div>

            <div>
              <h4>CryptoBastards en los medios:</h4>
              <p>
                -<span> </span><a href="https://es.beincrypto.com/beincrypto-entra-gamefi-mano-crypto-bastards/?utm_source=telegram&utm_medium=social" target="_blank" rel="noreferrer">Beincrypto</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              </p>

              <hr />
            </div>
          </div>
      </div>
    </div>
  );
};

export default App;
